import React from 'react'
import './NextEvents.css'
import Slider from '../../Slider/Slider';

import Miura from '../../../assets/images/nextEvents/miura.png'
import Argy from '../../../assets/images/nextEvents/Argy.png'
import versosvivos from '../../../assets/images/nextEvents/Festival-Versos-Vivos.png'
import hc from '../../../assets/images/nextEvents/HC.png'
import Gardenh from '../../../assets/images/nextEvents/Garden-Halloween-rs.png'
import orishas from '../../../assets/images/nextEvents/ORISHAS.png'
import toolroom from '../../../assets/images/nextEvents/Toolroom.png'
import allinone from '../../../assets/images/nextEvents/ALLINONE_POST_SALAURBANA_131124.png'


const nextEvents = [
  {
    image: Miura,
    title: 'Miura',
    buy: 'https://boletos.miuraboxing.com'
  },

  {
    image:orishas ,
    title: 'orishas',
    buy: 'https://gardenclub.com.mx/'
  },
  {
    image:allinone ,
    title: 'Sub Zero Project',
    buy: 'https://www.passline.com/eventos/sub-zero-project-mexico-city1'
  },


];

export default function NextEvents() {
  return (
    <div className='NextEvents'>
      <Slider 
        items={nextEvents}
        handleClick={() => console.log('do nothing')}
      />
    </div>
  )
}
